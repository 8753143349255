import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const {
    sessionStore: { token },
  } = useStores();
  const auth = token;

  if (!auth && !location.pathname.includes('/assets/')) {
    return (
      <Navigate
        to="/portal/iniciar-sesion"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export default observer(PrivateRoute);