/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
import MyCertificationsScreen from 'screens/certificaciones';
import MainLayout from 'components/layouts/mainLayout';
import HomeScreen from 'screens/inicio';
import FinanceScreen from 'screens/financiamiento';
import FinanceSubtipoRoutes from 'screens/financiamiento/SubtipoRoutes';
import CertsSubtipoRoutes from 'screens/certificaciones/SubtipoRoutes';
import SeguroSubtipoRoutes from 'screens/seguros/SubtipoRoutes';
import InsuranceScreen from 'screens/seguros';
import MyFieldsScreen from 'screens/mis-datos/mis-campos';
import MyProfileScreen from 'screens/perfil';
import ProcedureLayout from 'components/layouts/ProcedureLayout';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import {
  LazyAuditoriaDashboardScreen,
  LazyComunityScreen,
  LazyMyAccountScreen,
  LazyUsersScreen,
  LazyCultivosScreen,
  LazyOrdenesTrabajoScreen,
} from './lazyRoutes';
import { Treatments } from 'models/general';
import { Box } from '@mui/material';
import { SpinnerXL } from '../components/common/icon/icons/spinner';
import { MisEmpresasContext } from '../contexts/MisEmpresas';
import AgrologyIAIndexRoute from './agrology-ai';

type Props = {
  treatments: Treatments;
};

const Main = observer(({ treatments }: Props) => {
  const {
    fieldsStore,
    sessionStore,
    sessionStore: { currentUser, canAccessUrl, menu },
    cuitsStore: { getCuits },
  } = useStores();
  const MENU_EMPTY = 0;
  const getUserData = async () => {
    sessionStore.getMeUser();
    getCuits();
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fieldsStore.setSplitioTabMode(treatments.tabLotes.treatment === 'on');
    sessionStore.setSplitioArgenpymes(treatments.argenpymes.treatment === 'on');
    sessionStore.setSplitioClima(treatments.clima.treatment === 'on');
    sessionStore.setSplitioBannerDaruma(
      treatments.bannerDaruma.treatment === 'on',
    );
    sessionStore.setSplitioOneSoilBanner(treatments.oneSoil.treatment === 'on');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatments]);

  if (menu.length === MENU_EMPTY)
    return (
      <Box
        sx={{
          display: 'grid',
          placeContent: 'center',
          height: '100vH',
        }}
      >
        <SpinnerXL />
      </Box>
    );
  return (
    <MainLayout userData={currentUser}>
      <Helmet>
        <title>Agrology | Construyendo valor con tus datos</title>
      </Helmet>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div></div>}>
              <HomeScreen />
            </Suspense>
          }
        />
        <Route
          path="/comunidad"
          element={
            canAccessUrl('/comunidad') ? (
              <Suspense fallback={<div></div>}>
                <LazyComunityScreen />
              </Suspense>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/* Agregar rutas para Viterra */}
        <Route
          path="/certificaciones/:alias/*"
          element={
            treatments.financing.treatment === 'on' &&
            canAccessUrl('/certificaciones') ? (
              <ProcedureLayout procedure="certificaciones">
                <Helmet>
                  <title>Agrology | Sustentabilidad</title>
                </Helmet>
                <CertsSubtipoRoutes />
              </ProcedureLayout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/certificaciones"
          element={
            canAccessUrl('/certificaciones') ? (
              <>
                <Helmet>
                  <title>Agrology | Sustentabilidad</title>
                </Helmet>
                <MyCertificationsScreen />
              </>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/financiamiento/:alias/*"
          element={
            treatments.financing.treatment === 'on' &&
            canAccessUrl('/financiamiento') ? (
              <ProcedureLayout procedure="financiamiento">
                <Helmet>
                  <title>Agrology | Financiamiento</title>
                </Helmet>
                <FinanceSubtipoRoutes />
              </ProcedureLayout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/financiamiento"
          element={
            treatments.financing.treatment === 'on' &&
            canAccessUrl('/financiamiento') ? (
              <>
                <Helmet>
                  <title>Agrology | Financiamiento</title>
                </Helmet>
                <FinanceScreen />
              </>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/seguros/:alias/*"
          element={
            treatments.insurance.treatment === 'on' &&
            canAccessUrl('/seguros') ? (
              <ProcedureLayout procedure="seguros">
                <Helmet>
                  <title>Agrology | Seguros</title>
                </Helmet>
                <SeguroSubtipoRoutes />
              </ProcedureLayout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/seguros"
          element={
            treatments.insurance.treatment === 'on' &&
            canAccessUrl('/seguros') ? (
              <>
                <Helmet>
                  <title>Agrology | Seguros</title>
                </Helmet>
                <InsuranceScreen />
              </>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/micuenta"
          element={
            canAccessUrl('/micuenta') ? (
              <Suspense fallback={<div></div>}>
                <MisEmpresasContext>
                  <LazyMyAccountScreen />
                </MisEmpresasContext>
              </Suspense>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/auditoriadigital/*"
          element={
            canAccessUrl('/auditoriadigital') ? (
              <Suspense fallback={<div></div>}>
                <LazyAuditoriaDashboardScreen />
              </Suspense>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/misdatos/cultivos"
          element={
            // canAccessUrl('/auditoriadigital') ? (
            <Suspense fallback={<div></div>}>
              <LazyCultivosScreen />
            </Suspense>
            // ) : (
            //   <Navigate to="/" replace />
            // )
          }
        />

        <Route
          path="/misdatos/ordentrabajo"
          element={
            // canAccessUrl('/auditoriadigital') ? (
            <Suspense fallback={<div></div>}>
              <LazyOrdenesTrabajoScreen />
            </Suspense>
            // ) : (
            //   <Navigate to="/" replace />
            // )
          }
        />

        <Route
          path="/misdatos/campos"
          element={
            canAccessUrl('/misdatos') ? (
              <MyFieldsScreen />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/misdatos/*"
          element={<Navigate to="/misdatos/cultivos" replace />}
        />

        <Route
          path="/usuariosinvitados"
          element={
            canAccessUrl('/usuariosinvitados') ? (
              <Suspense fallback={<div></div>}>
                <LazyUsersScreen />
              </Suspense>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="/ayuda" element={<HomeScreen />} />
        <Route path="/perfil" element={<MyProfileScreen />} />
        <Route path="/*" element={<Navigate to="/" />} />

        <Route
          path="/AgrologyIA"
          element={
            canAccessUrl('/AgrologyIA') ? (
              <Suspense fallback={<div></div>}>
                <AgrologyIAIndexRoute />
              </Suspense>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </MainLayout>
  );
});

export default Main;
