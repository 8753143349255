import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Box, Divider } from '@mui/material';
import TextInput from 'components/common/inputs/text';
import { P3 } from 'components/common/typography/styles';
import LinkBtn from 'components/common/buttons/linkBtn';
import colors from 'theme/colors';
import api from 'services/api/api';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import { LoginSchema } from 'utils/schema-validations';
import { Strings } from 'constants/strings';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthButton } from 'components/common/buttons/authButton';
import { StyledButton } from '../buttons/button/index.styled';
import { SpinnerSMW } from '../icon/icons/spinner';
import { externalLogin } from 'services/api/authApi';

enum FieldNames {
  fieldEmail = 'email',
  fieldPassword = 'password',
}

interface customizeLocationState {
  from: { pathname: string; action: string; state?: any | null };
}

function locationStateExists(state: unknown): state is customizeLocationState {
  return typeof state === 'object' && state !== null && 'from' in state;
}

const LoginForm: React.FC = observer(() => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const location = useLocation();

  const goTo: () => string = () => {
    /*
     * El actionType proviene solo cuando haces logout
     * */
    if (locationStateExists(location.state))
      return location.state.from.pathname;

    return '/';
  };

  const navigate = useNavigate();
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    [FieldNames.fieldEmail]: '',
    [FieldNames.fieldPassword]: '',
  };

  const loginUser = async (values: Record<string, string>) => {
    const token = (await recaptchaRef?.current?.executeAsync()) || '';
    recaptchaRef?.current?.reset();

    const response = await api.auth.getToken(
      values[FieldNames.fieldEmail],
      values[FieldNames.fieldPassword],
      token,
    );

    if (response.status && response.status === 400) {
      enqueueSnackbar(Strings.forms.login.error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

    if (response.token) {
      sessionStore.setToken(response.token);
      api.api.setToken(response.token);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      navigate(goTo() as string);
    }
  };

  const onExternalUserSuccess = (token: string | undefined) => {
    if (token) {
      sessionStore.setToken(token);
      api.api.setToken(token);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      navigate(goTo() as string);
    }
  };

  const onExternalUserFailure = (message: string | undefined) => {
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={loginUser}
      validationSchema={LoginSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <>
            <Box marginBottom="16px" width="100%">
              <TextInput
                id={FieldNames.fieldEmail}
                label="Tu email"
                disabled={false}
                name={FieldNames.fieldEmail}
                width="421px"
                height="48px"
                type="email"
                handleSubmit={handleSubmit}
                placeholder="Ingresá tu email"
              />
            </Box>
            <Box marginBottom="16px" width="100%">
              <TextInput
                id={FieldNames.fieldPassword}
                label="Tu contraseña"
                disabled={false}
                name={FieldNames.fieldPassword}
                width="421px"
                height="48px"
                type="password"
                placeholder="Ingresá tu contraseña"
                handleSubmit={handleSubmit}
              />
            </Box>
            <>
              <ReCAPTCHA
                sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
                size="invisible"
                hl="es"
              />
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{
                  xs: 'center',
                  laptop: 'center',
                }}
                justifyContent={{
                  xs: 'center',
                  laptop: 'flex-start',
                }}
                gap="15px"
                mt="24px"
                width="100%"
              >
                <StyledButton
                  variant="contained"
                  size="large"
                  sx={{
                    padding: '12px 16px',
                    fontSize: '20px',
                  }}
                  fullWidth
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  {isSubmitting ? <SpinnerSMW /> : 'Ingresar'}
                </StyledButton>
                <Divider
                  variant="fullWidth"
                  orientation="horizontal"
                  sx={{ width: '100%' }}
                >
                  o
                </Divider>
                <AuthButton
                  onSuccess={(tokenResponse) => {
                    externalLogin({
                      accessToken: tokenResponse.access_token,
                      onSuccess: onExternalUserSuccess,
                      onFailure: onExternalUserFailure,
                    });
                  }}
                >
                  Ingresar con Google
                </AuthButton>
                <Box
                  width="100%"
                  mt="32px"
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                  gap="8px"
                >
                  <P3 color={colors.tertiaryBase}>
                    ¿No tenés cuenta Agrology?
                  </P3>
                  <LinkBtn onClick={() => navigate('/portal/crear-cuenta')}>
                    <P3
                      style={{
                        margin: 0,
                        color: colors.complementaryGreen,
                        textDecoration: 'underline',
                        textUnderlineOffset: '1px',
                      }}
                    >
                      Registrate acá
                    </P3>
                  </LinkBtn>
                </Box>
                <LinkBtn onClick={() => navigate('/portal/recuperar-cuenta')}>
                  <P3
                    style={{
                      color: colors.complementaryGreen,
                      textDecoration: 'underline',
                      textUnderlineOffset: '1px',
                    }}
                  >
                    Olvidé mi contraseña
                  </P3>
                </LinkBtn>
              </Box>
            </>
          </>
        );
      }}
    </Formik>
  );
});

export default LoginForm;
